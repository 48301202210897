<template>
  <vca-card>
    <h2>{{ $t("users.list.header") }}</h2>
    <vca-card class="shadowed vca-center">
      <AsyncUsersTable :loaded="initialized" />
      <vca-popup
        v-if="current != null"
        :show="current != null"
        :title="$t('users.popup.edit', { 0: current.display_name })"
        @close="close()"
      >
        <UserProfile />
      </vca-popup>
    </vca-card>
  </vca-card>
</template>

<script>
import { mapGetters } from "vuex";

import AsyncUsersTable from "@/components/users/AsyncUsersTable";
import UserProfile from "@/components/users/UserProfile";
export default {
  name: "UsersList",
  components: { AsyncUsersTable, UserProfile },
  data() {
    return {
      initialized: false,
    };
  },
  created() {
    this.current = null;
    this.$store.dispatch({ type: "refresh" }).then(() => {
      this.$store.commit("users/pg/route", "users");
      this.$store.commit("users/pg/context", "users/pg");
      if (this.hasSystemPermission()) {
        this.$store
          .dispatch({
            type: "users/pg/request",
            data: { initial: true, abort: true },
          })
          .finally(() => {
            this.initialized = true;
          });
        this.$store.dispatch({ type: "crews/public" });
      } else if (this.hasPoolPermission()) {
        this.$store.commit("users/pg/filter", {
          crew_id: this.user.crew.crew_id,
        });
      }
    });
  },
  computed: {
    ...mapGetters({
      user: "user/current",
      hasPoolPermission: "user/roles/hasPoolPermission",
      hasSystemPermission: "user/roles/hasSystemPermission",
    }),
    current: {
      set(value) {
        this.$store.state.users.current = value;
      },
      get() {
        return this.$store.state.users.current;
      },
    },
  },
  methods: {
    close() {
      this.$store.commit("users/clean_current");
    },
  },
};
</script>
